@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.registration-confirm {

    .label-value-form {
        .row {
            align-items: flex-start;
    
            &.row-back {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
            
                & > a{
                    font-size: $defaultFontSize;
                }
            }
    
            &.center {
                justify-content: center;
            }
    
            &:not(:first-child) {
                margin-top: 15px;
            }
        
            & > .col {
                display: flex;
                flex-direction: column;
                flex: 1 1 auto;
                min-width: 300px;
                margin: 0 15px;
    
                &:first-child {
                    margin-left: 0;
                }
    
                &:last-child {
                    margin-right: 0;
                }
    
                & > .label {
                    flex: 1 1 auto;
                    text-align: left;
                    padding: 0 0 2px 2px;
                }
    
                & > .value {
                    position: relative;
    
                    .password-legend{
                        padding-top: 3px;
                        color: $color3;
                        font-size: 0.875em;
                    }
                    
                    .lib-form-component.textinput-component {
                        & > .form-component-content {
                            & > input.form-component-control {
                                padding-left: $componentHeight;
                            }
                        }
                    }
    
                    & > .selectbox-wrapper {
                        & > .lib-form-component.selectbox-component {
                            padding: 0 0 0 $componentHeight;
    
                            & > .form-component-content {
                                & > .options {
                                    margin-right: 2px;
                                }
                            }
                        }
                    }
    
                    & > .form-input-icon{
                        &:after{
                            content:'';
                            display: block;
                            position: absolute;
                            right: 5px;
                            top: 10px;
                            bottom: 10px;
                            width: 1px;
                            background-color: $color3;
                        }
            
                        .fa.fa-user,
                        .fa.fa-at,
                        .fa.fa-globe,
                        .fa.fa-lock{
                            font-size: 15px;
                        }
                    }
                }
            }
    
            &.row-terms {
                align-items: center;
    
                & > .checkbox-component > .form-component-content {
                    padding-right: 5px;
                    & > .form-component-control {
                        color: $color5;
                    }
                }
            }
    
            &.buttons-container {
                border-top: none;
            }
        }
    }

    @include breakpoint-down($breakpointMd) {
        .label-value-form {
            .row {
                flex-direction: column;
                align-items: stretch;

                & > .col {
                    margin: 0;
                    min-width: 190px;

                    &:not(:first-child) {
                        margin-top: 15px;
                    }
                }

                &.row-terms {
                    flex-direction: row;
                    flex-wrap: wrap;
                    align-items: center;
                }

                &.buttons-container > button {
                    width: 100%;
                }

                &.row-back {
                    justify-content: center;
                }
            }

            &.registration-success-panel {
                & > .info {
                    text-align: center;
                }
            }
        }
    }
}