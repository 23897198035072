@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

$formComponent_color0: #000 !default;
$formComponent_color1: #FFF !default;
$formComponent_color1_opacity75: rgba(255, 255, 255, .75) !default;
$formComponent_color1_opacity50: rgba(255, 255, 255, .5) !default;
$formComponent_color1_opacity25: rgba(255, 255, 255, .25) !default;
$formComponent_color2: #333 !default;
$formComponent_color3: #777 !default;
$formComponent_color4: #f2f2f2 !default;
$formComponent_color5: #2d67a7 !default;
$formComponent_color6: #aaa !default;
$formComponent_color7: #548ac5 !default;
$formComponent_color8: #92b1ca !default;
$formComponent_color9: #e80a0a !default;
$formComponent_color10: #06c !default;
$formComponent_color11: #39c !default;
$formComponent_color12: #ddd !default;
$formComponent_color13: #693 !default;
$formComponent_color14: #9c3 !default;
$formComponent_color15: #ff4700 !default;
$formComponent_color16: #f90 !default;
$formComponent_color17: #2196f3 !default;
$formComponent_color18: #c3c3c3 !default;
$formComponent_color19: #eceff1 !default;
$formComponent_color20: rgba(51, 153, 204, 0.5) !default;
$formComponent_color21: #f36128 !default;
$formComponent_color22: #ddd !default;
$formComponent_color23: #a70000 !default;
$formComponent_color24: #808080 !default;
$formComponent_color25: #737a82 !default;
$formComponent_color26: #e8e8e8 !default;

$formComponent_labelFontSize: 12px !default;
$formComponent_labelFontColor: $formComponent_color0 !default;
$formComponent_labelDisabledFontColor: $formComponent_color3 !default;
$formComponent_contentHeight: 30px !default;
$formComponent_contentFontSize: 12px !default;
$formComponent_contentFontFamily: inherit !default;
$formComponent_contentFontColor: $formComponent_color9 !default;
$formComponent_contentPaddingLeft: 5px !default;
$formComponent_contentPaddingRight: 10px !default;
$formComponent_contentDisabledFontColor: $formComponent_color3 !default;
$formComponent_contentDisabledBackgroundColor: $formComponent_color6 !default;
$formComponent_contentUnderlineColor: $formComponent_color3 !default;
$formComponent_contentUnderlineFocusedColor: $formComponent_color14 !default;
$formComponent_okStatusIconColor: $formComponent_color14 !default;
$formComponent_statusIconPaddingLeft: 5px !default;
$formComponent_errorMessageColor: $formComponent_color9 !default;
$formComponent_errorMessageFontSize: 12px !default;
$formComponent_errorStatusIconColor: $formComponent_color9 !default;
$formComponent_errorUnderlineColor: $formComponent_color9 !default;

//@import './variables';

// Tridy pro univerzalni strukturu vlastnich formularovych komponent
.lib-form-component{
	position: relative;

	&.focused{
		.form-component-content {
			&:before{
				background: $formComponent_contentUnderlineFocusedColor;
				height: 2px;
			}
		}
	}

	&.error{
		.form-component-content {
			&:before{
				background-color: $formComponent_errorUnderlineColor;
			}

			//.form-component-status-icon {
			//	display: flex;
			//
			//	.form-component-error-icon {
			//		display: flex;
			//	}
			//}
		}
	}

	&.disabled{
		.form-component-label{
			color: $formComponent_labelDisabledFontColor;
		}

		.form-component-content {
			//background-color: $formComponent_contentDisabledBackgroundColor;
			//color: $formComponent_contentDisabledFontColor;

			&:hover{
				&:after{
					display: none;
				}
			}

			.form-component-control{
				cursor: not-allowed;
			}

			.form-component-status-icon{
				display: none;
			}
		}
	}



	.form-component-label{
		display: block;
		font-size: $formComponent_labelFontSize;
		cursor: default;
		color: $formComponent_labelFontColor;
	}

	.form-component-content{
		//height: $formComponent_contentHeight;
		font-size: $formComponent_contentFontSize;
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		position: relative;
		padding-left: $formComponent_contentPaddingLeft;
		padding-right: $formComponent_contentPaddingRight;

		&:before{
			content: ' ';
			position: absolute;
			height: 1px;
			right: 0;
			left: 0;
			bottom: 0;
			background-color: $formComponent_contentUnderlineColor;
			z-index: 1;
		}

		&:after{
			content: ' ';
			position: absolute;
			height: 2px;
			width: 0;
			left: 0;
			bottom: 0;
			background-color: $formComponent_contentUnderlineFocusedColor;
			transition: all 0.250s ease-out;
			z-index: 2;
		}

		&:hover{
			&:after{
				width: 100%;
			}
		}

		.form-component-add-on-start, .form-component-add-on-end{
			flex: 0 0 auto;
			display: flex;
			align-items: center;
			justify-content: center;
			min-height: $formComponent_contentHeight;

			&.form-component-add-on-start {
				padding-right: $formComponent_statusIconPaddingLeft;
			}

			&.form-component-add-on-end {
				padding-left: $formComponent_statusIconPaddingLeft;
			}
		}

		.form-component-control{
			//padding-left: 10px;
			display: flex;
			flex: 1 1 auto;
			height: $formComponent_contentHeight;
			min-height: $formComponent_contentHeight;
			line-height: $formComponent_contentHeight;
			align-items: center;
			cursor: pointer;
			font-size: $formComponent_contentFontSize;

			// "i" ma svuj font z Font-Awesome a tak se nesmi prepsat
			&:not(i){
				font-family: $formComponent_contentFontFamily;
			}

			&:focus{
				outline: none;
			}

			&:disabled{
				background-color: transparent;
				//color: $formComponent_contentFontColor;
			}
		}

		.form-component-status-icon {
			align-items: center;
			flex: 0 0 auto;
			align-self: stretch;
			display: flex;
			justify-content: center;
			min-height: $formComponent_contentHeight;

			.form-component-ok-icon,
			.form-component-error-icon {
				display: flex;
				font-size: $formComponent_contentFontColor;
				pointer-events: none;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				margin-left: $formComponent_statusIconPaddingLeft;
			}

			.form-component-error-icon {
				color: $formComponent_errorStatusIconColor;
			}

			.form-component-ok-icon {
				color: $formComponent_okStatusIconColor;
			}
		}

		.disabled-overlay{
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			background-color: $formComponent_contentDisabledBackgroundColor;
			opacity: 0.5;
			z-index: 1;
			cursor: not-allowed;
		}
	}

	.form-component-error-message{
		margin-top: 3px;
		min-height: $formComponent_errorMessageFontSize;
		font-size: $formComponent_errorMessageFontSize;
		text-align: right;
		color: $formComponent_errorMessageColor;
	}
}

$textinputComponent_placeholderBackgroundColor: $formComponent_color3 !default;
$textinputComponent_textColor: inherit !default;

.lib-form-component.textinput-component{
	color: $textinputComponent_textColor;

	.form-component-content {

		.form-component-control{
			min-width: 0;
			width: 100%; // HTML input type=text ma tendenci mit vynucenou minimalni sirku; tohle zlepsi chovani
			border: none;
			height: auto;
			padding: 0;
			margin: 0;
			color: inherit;

			&::-webkit-input-placeholder { /* WebKit, Blink, Edge */
				color: $textinputComponent_placeholderBackgroundColor;
			}

			&:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
				color: $textinputComponent_placeholderBackgroundColor;
				opacity:  1;
			}

			&::-moz-placeholder { /* Mozilla Firefox 19+ */
				color: $textinputComponent_placeholderBackgroundColor;
				opacity:  1;
			}

			&:-ms-input-placeholder { /* Internet Explorer 10-11 */
				color: $textinputComponent_placeholderBackgroundColor;
			}

			&::-ms-input-placeholder { /* Microsoft Edge */
				color: $textinputComponent_placeholderBackgroundColor;
			}
		}

		textarea{
			padding-left: 0;
			min-height: $formComponent_contentHeight;
		}
	}

	&.multiline {
		.form-component-content {
			align-items: flex-start;
			height: auto;

			.form-component-control {
				height: auto;
			}
		}
	}

	//&.error, &.ok {
	//	&:not(.add-on-end) {
	//		.form-component-add-on {
	//			padding-right: 0;
	//		}
	//	}
    //
	//	&.add-on-end{
	//		.form-component-add-on {
	//			padding-left: 0;
	//		}
	//	}
	//}

	//&.disabled{
	//	.form-component-content {
	//		.form-component-control{
	//			background-color: $color13;
	//		}
    //
	//		.form-component-add-on{
	//			background-color: $color13;
	//		}
	//	}
	//}
    //
	//&.add-on-end{
	//	.form-component-control{
	//		order: 0;
	//	}
    //
	//	.form-component-add-on{
	//		order: 2;
	//	}
    //
	//	.form-component-icon{
	//		order: 1;
	//	}
	//}
}

.lib-form-component.textinput-component {
    background-color: transparent;

    & > .form-component-content {
        padding: 0;

        &:before {
            content: none;
        }
        & > input {
            &:before {

            }
        }
        &:after {
            content: none;
        }

        & > input.form-component-control {
            border: 1px solid $color3;
            border-radius: 3px;
            height: $componentHeight;
            padding: 5px;

            &:focus {
                border-color: $color10;
                outline-color: $color11;
                outline-offset: -2px;
                outline-style: auto;
                outline-width: 5px;
            }
        }

        & > .form-component-status-icon {
            display: none;
        }
    }
}