// @import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&subset=latin-ext'); 

@import './variables.scss';
@import './mixins.scss';

$formComponent_contentUnderlineFocusedColor: $color18;
$formComponent_contentUnderlineColor: $color18;
$formComponent_errorMessageFontSize: 10px;
.lib-form-component{
	background-color: $color1;
}
$textinputComponent_textColor: $color2;

*{
	box-sizing: border-box;
	font-family: Tahoma, Verdana, Arial;
}


html, body{
	margin: 0;
	padding: 0;
	height: 100%;
	// height: auto;
	min-height: 100%;
	font-family: Tahoma, Verdana, Arial;
	font-size: $defaultFontSize;
	// display: flex;
}

body{
	// flex: 1 1 auto;
	display: flex;
	flex-direction: row;

	&.noscroll{
		overflow: hidden;
	}

	&.print{
		display: block;

		#root{
			display: block;

			main{
				display: block;

				.main-content-container{
					display: block;
					//overflow: initial; 
				}
			}
		}
	}

	&:not(.print){
		#root{
			main{
				.main-content-container{
					overflow: auto;
				}
			}
		}
	}

	#root{
		flex: 1 1 auto;
		display: flex;
		flex-direction: row;

		.filter .lib-form-component.selectbox-component {
			border-top-left-radius: $borderRadiusMain;
			border-top-right-radius: $borderRadiusMain;
		}

		main{
			display: flex;
			flex-direction: column;
			flex: 1 1 auto;

			overflow: auto;
			margin-top: 0 !important;

			.main-content-container {
				display: flex;
				flex-direction: row;
				flex: 1 1 auto;
				justify-content: center;
				//overflow: auto;
				//margin: auto;

				@include breakpoint-height-down(400px){
					flex: 1 0 auto;
				}

				//.inner-container{
				//	flex: 1 0 auto;
				//	section {

				& > * {
					//width: $containerXl;
					//margin: 0 auto;
					flex: 1 1 auto;

					//@include breakpoint-down($breakpointXl) {
					//	width: $containerLg;
					//}
                    //
					//@include breakpoint-down($breakpointLg) {
					//	width: $containerMd;
					//}
                    //
					//@include breakpoint-down($breakpointMd) {
					//	width: $containerSm;
					//}
                    //
					//@include breakpoint-down($breakpointSm) {
					//	width: $containerXs;
					//}
				}
				//
				//}

				.main-content {
					flex: 1 1 $appMaxWidth;
					@include flex('column', 'flex-start', 'stretch');
					max-width: $appMaxWidth;
					padding-bottom: 30px;
					overflow: auto;
				}
			}

			.default-logo {
				background-image: url(/images/xmarton/logo_symbol.svg);
				background-size: 140px;
				background-repeat: no-repeat;
				background-position: center 15vh;
			}

			footer{
				margin-top: 0;
				padding: 8px;
				flex: 0 0 auto;
				border-top: 1px solid $color6;

				&.login-style{

					.login-footer__link {
						color: $color5;
						text-decoration: underline;
						cursor: pointer;

						&:hover {
							color: $color7;
						}
					}
				}

				.footer-container{
					margin: 0 auto;
					display: flex;
					flex-direction: row;
					justify-content: center;
					align-items: center;
					font-size: 12px;
					//width: $containerXl;

					.footer-content{
						display: flex;
						flex: 1 1 auto;
						justify-content: flex-end;
						font-size: 1rem;
					}

					@include breakpoint-down($breakpointSm) {
						//width: $containerXs;
						flex-direction: column;
						justify-content: center;

						div{
							flex: 0 0 auto;
							margin-bottom: $defaultMargin;

							&.left,
							&.middle,
							&.right{
								text-align: center;
							}
						}
					}

					a{
						font-size: 12px;
					}
				}
			}
		}

		.container{
			width: $containerXl;
			margin: 0 auto;

			@include breakpoint-down($breakpointXl) {
				width: $containerLg;
			}

			@include breakpoint-down($breakpointLg) {
				width: $containerMd;
			}

			@include breakpoint-down($breakpointMd) {
				width: $containerSm;
			}

			@include breakpoint-down($breakpointSm) {
				width: $containerXs;
			}
		}

		.main-list {
			border-bottom-right-radius: $panelBorderRadius;
			border-bottom-left-radius: $panelBorderRadius;
		}

		button:hover .fa-times {
			color: $color0;
		}
	}

	#root:not(:has(.company-settings)) {
		@include breakpoint-height-down(600px){
			// flex: 1 0 auto;
		}
	}

	.menu, .submenu{
		list-style-type: none;
		margin: 0;
		padding: 0;
		display: inline-block;
		padding-top: 3px;
		font-size: 14px;
		text-transform: uppercase;

		&.submenu{
			font-size: 10px;
		}

		&.align-right {
			float: right;
		}

		li {
			margin: 0;
			padding: 0;
			height: $menuHeight - 3px;
			float: left;

			.menu-link {
				margin: 0;
				padding: 0 10px;
				display: block;
				cursor: pointer;
				text-decoration: none;
				border: none;
				color: $color3;
				background-color: transparent;
				height: $menuHeight - 3px;
				line-height: $menuHeight - 3px;

				&:hover {
					background-color: $color19;
					color: $color0;
				}

				&.active {
					background-color: $color18;
					color: $color0;
					text-decoration: none;
					cursor: default;
				}
			}
		}
	}
}







//
//html, body{
//	margin: 0;
//	padding: 0;
//	height: 100%;
//	min-height: 100%;
//}
//
//html.noscroll{
//	overflow: hidden !important;
//}
//
//
//body, html, #root, main{
//	margin: 0;
//	padding: 0;
//	@include absolute(0,0,0,0);
//	min-width: $appMinWidth;
//}
//html{
//	align-items: stretch;
//	min-height: 100%;
//	min-width: 100%;
//	font-size: 12px;
//	font-family: Tahoma, Verdana, Arial;
//
//}
//body, #root, main{
//}

.logo-vertical{
	height: 70px;
	background-image: url('/images/xmarton/logo_vertical.svg');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: contain;
	margin: 0 (70px / 3) (70px / 3) (70px / 3);
}
.logo-horizontal{
	height: 70px;
	background-image: url('/images/xmarton/logo_horizontal.svg');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: contain;
	margin: 0 (70px / 3) (70px / 3) (70px / 3);
}

a, input[type=checkbox]{
	&:focus{
		outline-color: $color11;
		outline-offset: -2px;
		outline-style: auto;
		outline-width: 5px;
	}
}

.link {
	border: none;
	cursor: pointer;
	text-decoration: underline;
	color: $color5;
	padding: 0;
	margin: 0;
	background-color: transparent;

	&:hover{
		color: $color11;
	}
}

.text-center{
	text-align: center !important;
}
.text-right{
	text-align: right !important;
}
.text-left{
	text-align: left !important;
}
.nowrap{
	white-space: nowrap !important;
}

.white-shadow-box{
	background-color: #fff;
	box-shadow: 0 2px 5px rgba(0,0,0,.3);
	border-radius: 3px;
}

.pending-box{
	padding: 20px;
	text-align: center;
	width: 100%;

	z-index: 1;
	position: absolute;
	top: 0;
	left: 0;
	background-color: rgba(0,0,0,.35);
	height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

}

.loader-box{
	background-color: #fff;
	padding: 20px;
	border-radius: 3px;
	display: flex;
	justify-content: center;
	align-items: center;

	.loader-text{
		padding-left: 10px;
	}
}

.success-text{
	color: $color13;
}

.error-text{
	color: $color23;
}

.infobox{
	margin: 5px;
	padding: 10px;
	font-style: italic;
	line-height: 1.5;
	font-size: 12px;
	color: #555;
	background-color: #eee;
}

.modal-step{
	padding: 10px;
	font-size: 14px;
	border-bottom: 1px solid #ddd;
	color: #aaa;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: default;

	&__arrow{
		padding: 0 14px;
		flex: 0 0 auto;
	}

	&__item{
		flex: 0 0 auto;

		&--done{
			color: #000;
		}

		&--current{
			font-weight: bold;
			color: #4d4d4d;
		}
	}
}

//d-flex d-column jc-center ai-center
.tbl{
	border-collapse: collapse;

	th, td{
		line-height: 1.5;
	}

	&--row-underline{
		th, td{
			border-bottom: 1px solid #ddd;
		}
	}

	&--th-alt{
		th{
			font-weight: normal;
			background-color: #ddd;
		}
		&.tbl--row-underline{
			th{
				border-bottom: 1px solid #fff;
			}
		}
	}


	&--width{
		&--100{
			width: 100%;
		}
	}

	&--margin-tb{
		&--15{
			margin-top: 15px;
			margin-bottom: 15px;
		}
	}
	&--cell-padding{
		&--4 {
			th, td {
				padding: 4px;
			}
		}
		&--6 {
			th, td {
				padding: 6px;
			}
		}
	}

	&__header{
		&--underline{
			td, th{
				border-bottom: 1px solid #aaa;
			}
		}
	}

	&--text-size{
		&--16{
			font-size: 16px;
		}
	}
}

.link-button{
	display: inline-block;
	appearance: none;
	border: none;
	padding: 0;
	margin: 0;
	background-color: transparent;
	cursor: pointer;

	&--edit{
		color: $color7;
	}
	&--delete{
		color: $color23;
	}
	&--warning{
		color: $color16;
	}
	&--size-16{
		font-size: 16px;
	}
	&--pr-10{
		padding-right: 10px;
	}
}

@import './button';
@import './form';
// @import './customer';
// @import './f-elements';
// @import './table';
// @import './list-table';
// @import './modal-list';
// @import '../containers/App/app';
// @import '../containers/Logbook/logbook';
// @import '../containers/Reports/reports';
// @import '../containers/Logbook/logbook-print';
// @import '../containers/Logbook/components/ContextMenu/contextmenu';
// @import '../containers/LogbookRoute/logbook-route';
// @import '../components/Itinerary/itinerary';
// @import '../components/DateSelector/date-selector';
// @import '../components/DateTimeInlineSelector/date-time-selector-inline';
// @import '../components/DynamicTable/dynamic-table';
// @import '../containers/Logbook/components/RefuelingAndCosts/refueling-and-costs';
// @import '../containers/Logbook/components/ManualCorrection/manual-correction';
