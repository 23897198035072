@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.login-container {
    &.registration-success {
        justify-content: center;
    }

    .login-form-wrapper {
        width: auto;
    
        .row {
            align-items: flex-start;

            &.row-back {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
            
                & > a{
                    font-size: $defaultFontSize;
                }
            }
    
            &.center {
                justify-content: center;
            }
    
            &:not(:first-child) {
                margin-top: 15px;
            }
        
            & > .col {
                display: flex;
                flex-direction: column;
                flex: 1 1 auto;
                min-width: 300px;
                margin: 0 15px;
    
                &:first-child {
                    margin-left: 0;
                }
    
                &:last-child {
                    margin-right: 0;
                }
    
                & > .label {
                    flex: 1 1 auto;
                    text-align: left;
                    padding: 0 0 2px 2px;
                }
    
                & > .value {
                    position: relative;
    
                    .lib-form-component.textinput-component {
                        & > .form-component-content {
                            & > input.form-component-control {
                                padding-left: $componentHeight;
                            }
                        }
                    }

                    & > .selectbox-wrapper {
                        & > .lib-form-component.selectbox-component {
                            padding: 0 0 0 $componentHeight;

                            & > .form-component-content {
                                & > .options {
                                    margin-right: 2px;
                                }
                            }
                        }
                    }

                    & > .form-input-icon{
                        &:after{
                            content:'';
                            display: block;
                            position: absolute;
                            right: 5px;
                            top: 10px;
                            bottom: 10px;
                            width: 1px;
                            background-color: $color3;
                        }
            
                        .fa.fa-user,
                        .fa.fa-at,
                        .fa.fa-globe,
                        .fa.fa-lock{
                            font-size: 15px;
                        }
                    }
                }
            }
    
            &.row-terms {
                align-self: center;
                flex-direction: column;
                // align-items: center;

                & > .term-item {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    align-items: center;
                }

                & > .checkbox-component > .form-component-content {
                    padding-right: 5px;
                    & > .form-component-control {
                        color: $color5;
                    }
                }
            }
    
            &.buttons-container {
                border-top: none;
            }
        }
    
        .terms {
            color: $color5;
            font-weight: bold;
        }

        &.registration-success-panel {
            margin: 5px;
            max-width: 450px;

            & > .result-part {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
    
                & > .icon {
                    font-size: 18px;
                    color: $color7;
                }
    
                & > .message {
                    padding-left: 15px;
                }
            }
    
            & > .info {
                padding-top: 15px;
                font-size: 14px;
                text-align: justify;
            }

            & > .row.link {
                padding-top: 20px;
            }
        }
    }
    
    @include breakpoint-down($breakpointMd) {
        &.registration-container > .login-page-logo {
            display: none;
        }

        .login-form-wrapper {
            .row {
                flex-direction: column;
                align-items: stretch;

                & > .col {
                    margin: 0;
                    min-width: 190px;

                    &:not(:first-child) {
                        margin-top: 15px;
                    }
                }

                &.row-terms {
                    align-self: flex-start;

                    & > .term-item {
                        flex-direction: row;
                        flex-wrap: wrap;
                    }
                }

                &.buttons-container > button {
                    width: 100%;
                }

                &.row-back {
                    justify-content: center;
                }
            }

            &.registration-success-panel {
                & > .info {
                    text-align: center;
                }
            }
        }
    }
}

.pending-row {
    @include flex('row', 'center', 'center');
    margin: 0 auto;

    & > .loader{
        width: 50px;
        height: 50px;
        background-image: url("/images/loaders/loader.svg");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 50px 50px;
        margin: 10px;
    }

    & > .text{
        font-style: italic;
        text-align: center;
        cursor: default;
        color: $color3;
    }
}
