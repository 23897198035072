@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

$vert-padding: 25px;

.notification{
	position: fixed;
	top: 0;
	right: 0;
	font-size: 13px;
	z-index: 13010;
	display: flex;
	flex-direction: column;
	width: 550px;
	max-width: 100%;

	.notification-item{
		flex: 0 1 auto;
		max-width: 550px;
		min-width: 200px;
		min-height: 24px + (2 * $vert-padding);
		background-color: $color1;
		box-shadow: $panelBoxShadow;
		margin: 10px;
		color: $color1;
		display: flex;
		flex-direction: row;
		border: 1px solid $color1;
		border-left: 15px solid $color1;
		border-radius: $panelBorderRadius;

		.description {
			flex: 1 1 auto;
			padding: $vert-padding 0 $vert-padding 20px;
			line-height: 24px;
			font-size: 16px;
			color: $color0;
			font-weight: 400;
			cursor: default;
			text-align: justify;
		}

		.close-box{
			flex: 0 0 auto;
			width: 40px;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: flex-start;
			padding: 0;

			.close-button{
				display: block;
				width: 40px;
				height: 40px;
				background-color: transparent;
				border: none;
				cursor: pointer;
				color: $color0;
				//border-left: 1px solid $color12;

				.fa{
					margin: 0;
					display: flex;
					flex-direction: row;
					justify-content: center;
					align-items: center;
					font-size: 20px;
				}

				&:focus{
					outline: none;
				}
			}
		}

		&.ok{
			border-color: $color13;
		}

		&.info{
			border-color: $color11;
		}

		&.warning{
			border-color: $color16;
		}

		&.error{
			border-color: $color9;
		}


	}
}
