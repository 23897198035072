$color0: #000;
$color1: #FFF;
$color1_opacity75: rgba(255, 255, 255, .75);
$color1_opacity50: rgba(255, 255, 255, .5);
$color1_opacity25: rgba(255, 255, 255, .25);
$color2: #333;
$color3: #777;
$color4: #f2f2f2;
$color5: #2d67a7;
//$color5: #007bff;
$color6: #aaa;
$color7: #548ac5;
$color8: #92b1ca;
$color9: #e80a0a;
$color10: #06c;
$color11: #39c;
$color12: #ddd;
$color13: #693;
$color14: #9c3;
$color15: #ff4700;
$color16: #f90;
$color17: #2196f3;
$color18: #c3c3c3;
$color19: #eceff1;
$color20: rgba(51, 153, 204, 0.5);
$color21: #f36128;
$color22: #eee;
$color23: #b30000;
$color24: #808080;
$color25: #737a82;
$color26: #e8e8e8;
$color27: #bbb;
$color28: #e9e9e9;
$color29: #f7f7f7;
$color30: #ebf5ff;
$color31: #f0f0f0;
$color32: #4d4d4d;
$color33: #3db370;
$color34: #67cb92;
$color35: #a0debb;
$color36: #a0262d;
$color37: #d3454c;
$color38: #e28388;
$color39: #595959;
$color40: hsl(0, 0%, 25%);
$color41: hsl(0, 0%, 99%);
$color42: #5182bd;
$color43: #d7d7d7;

$mainSize: 30px;
$padding: $mainSize;
$formItemSize: $mainSize * 1.25;

$breakpointXs: 321px;
$breakpointSm: 476px;
$breakpointMd: 768px;
$breakpointLg: 992px;
$breakpointXl: 1200px;

$containerXs: calc(100% - 20px);
//$containerSm: 540px;
$containerSm: calc(100% - 20px);
$containerMd: 720px;
$containerLg: 960px;
$containerXl: 1140px;

$defaultFontSize: 12px;
$defaultBoxShadow: 0px 2px 15px rgba(0,0,0,0.5);
$panelBoxShadow: 0 1px 6px rgba(0, 0, 0, 0.5);
$defaultMargin: 20px;
$defaultPadding: 20px;

$appMinWidth: 1024px;//1300px;
$appMinHeight: 430px;
$appMaxWidth: 1024px;

$menuHeight: 84px;

$borderRadiusMain: 3px;
$panelBorderRadius: 2px;
$modalBorderRadius: 6px;

$personSettHeightBreak: 430px;

$componentHeight: 37.5px;
