@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.loader-main-light{
	position: fixed;
	display: none;
	z-index: 100000;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: rgba($color0, 0.15);

	&.show{
		display: flex;
	}

	.loader-main-light-box{
		color: #fff;
		font-size: 50px;
	}

}
