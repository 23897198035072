@import './variables.scss';
@import './mixins.scss';

.button{
	background-color: $color6;
	display: inline-block;
	color: $color0;
	font-size: 14px;
	height: 34px;
	line-height: 34px;
	border: 0;
	padding: 0 15px;
	margin: 0;
	cursor: pointer;
	border-radius: $borderRadiusMain;
	text-decoration: none;

	&:active{
		opacity: 0.75;
	}

	.fa{
		width: $formItemSize;
		height: $formItemSize;
		@include flex('column');
		float: left;
	}

	span{
		display: inline-block;
		line-height: $formItemSize;
	}


	&.icon-only{
		padding: 0 15px;
	}

	&.size-small{
		height: 24px;
		line-height: 24px;
		padding: 0 8px;
		font-size: 12px;
		border-radius: 3px;

		&.icon-only{
			padding: 0 8px;
		}

		span{
			line-height: 24px;
		}
	}

	&:focus{
		outline-color: $color11;
		outline-offset: -2px;
		outline-style: auto;
		outline-width: 5px;
	}

	&:disabled, &.disabled{
		cursor: not-allowed;
	}

	&.main-primary{
		background-color: $color5;
		color: $color1;

		&:hover{
			background-color: $color7;
		}
		&:disabled, &.disabled{
			background-color: $color8;
			color: $color1_opacity75;
		}
	}

	&.main-quinary{
		background-color: $color1;
		border: 2px solid $color5;
		color: $color5;

		line-height: 30px;

		&.size-small{
			line-height: 20px;

			span{
				line-height: 20px;

			}
		}

		&:hover{
			background-color: $color1;
			border-color: $color0;
			color: $color0;
		}
		&:disabled, &.disabled{
			background-color: $color1;
			color: $color8;
			border-color: $color8;
		}
	}

	&.main-secondary{
		background-color: $color11;
		color: $color1;

		&:hover{
			background-color: $color20;
		}
		&:disabled, &.disabled{
			background-color: $color8;
			color: $color1_opacity75;
		}
	}

	&.main-cancel{
		background-color: $color3;
		color: $color1;

		&:hover{
			background-color: $color6;
		}
		&:disabled, &.disabled{
			background-color: $color4;
			color: $color27;
		}
	}

	&.main-warning{
		background-color: $color16;
		color: $color1;

		&:hover{
			//background-color: $color34;
			background-color: #ffbf5f;
		}
		&:disabled, &.disabled{
			opacity: 0.5;
			&:hover{
				background-color: $color16;
			}
		}
	}

	&.main-good{
		background-color: $color33;
		color: $color1;

		&:hover{
			background-color: $color34;
		}
		&:disabled, &.disabled{
			background-color: $color35;
			color: $color1_opacity75;
		}
	}

	&.main-bad{
		background-color: $color36;
		color: $color1;

		&:hover{
			background-color: $color37;
		}
		&:disabled, &.disabled{
			background-color: $color38;
			color: $color1_opacity75;
		}
	}
}
