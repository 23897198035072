@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

//.forgotten-password-confirm{
//	.transparent-modal{
//		position: fixed;
//		left: 0;
//		right: 0;
//		top: 0;
//		bottom: 0;
//		z-index: 10000000;
//		display: none;
//
//		&.active{
//			display: block;
//		}
//	}
//}

.forgotten-password-confirm, .forgotten-password-request, .reset-password-cancel{
	@include flex('column');
	padding-top: 40px;

	.form-wrapper{
		background-color: rgba(255, 255, 255, 0.5);
		padding: $padding;
		width: 440px;
		box-shadow: $panelBoxShadow;
		position: relative;
		border-radius: $panelBorderRadius;

		@media only screen and (max-height: (520px - 1)), (max-width: ($breakpointSm - 1)) {
			padding: ($padding / 2);
			width: 300px;
		}

		.forgotten-password-form{
			.password-legend{
				padding-top: 3px;
				color: $color3;
				font-size: 0.875em;
			}
		}

		.form-title{
			//font-family: 'Roboto';
			//font-weight: 500;
			text-align: center;
			font-size: 30px;
			margin-bottom: 30px;
		}

		.form-legend{
			color: $color3;
			margin-bottom: 15px;
		}

		.form-input-icon{
			&:after{
				content:'';
				display: block;
				position: absolute;
				right: 5px;
				top: 10px;
				bottom: 10px;
				width: 1px;
				background-color: $color3;
			}

			i{
				font-size: 15px;
			}
		}

		.form-error{
			display: block;
		}

		.form-row-buttons{
			button {
				width: 100%;
			}
		}

		.form-row-links{
			text-align: right;

			.form-link {
				font-size: $defaultFontSize
			}
		}

		.result-container{
			.pending{
				margin: 0 auto;

				.pending-loader{
					height: 50px;
					background-image: url("/images/loaders/loader.svg");
					background-repeat: no-repeat;
					background-position: center center;
					background-size: 50px 50px;
					margin: 10px;
				}

				.pending-text{
					font-style: italic;
					text-align: center;
					cursor: default;
					color: $color9;
				}
			}

			.result{
				//display: flex;
				//flex-direction: column;
				//justify-content: center;
				//align-items: center;
				margin-bottom: 20px;
				//cursor: default;

				.result-part1 {
					display: flex;
					flex-direction: row;
					justify-content: center;
					align-items: center;
					cursor: default;

					.result-icon {
						font-size: 18px;
						display: flex;
						flex-direction: row;
						justify-content: center;
						align-items: center;
						padding-right: 15px;

						&.result-ok {
							color: $color7;
						}

						&.result-error {
							color: $color9;
						}
					}

					.result-text {
						font-size: 14px;
						font-weight: 500;
					}
				}

				.result-part2 {
					padding-top: 20px;
					color: $color3;
				}
			}
		}
	}

	.transparent-modal{
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: 10000000;
		display: none;

		&.active{
			display: block;
		}
	}
}
