@import './variables.scss';
@import './mixins.scss';

form, .form{

	// Trida formatujici vsechny prime prvky vertikalne pod sebe
	.form-vertical-stack{
		display: flex;
		flex-direction: column;

		& > *{
			margin-bottom: 10px;

			&:last-child{
				margin-bottom: 0px;
			}
		}
	}

	&-required-star{
		color: $color9;
		padding-left: 5px;
	}

	//radek formulare
	&-row{
		padding: 0 0 ($formItemSize / 3) 0;

		&-error{
			.form-input{
				border-color: $color9;
			}
			.form-error{
				display: block;
			}

			.form-checkbox-label{
				border: 1px solid $color9;
				padding-left: 4px;
			}
		}

		&:last-child{
			padding: 0;
		}
	}

	//popisek formularoveho pole
	&-label-wrapper{
		height: $formItemSize * 0.55;
	}
	&-label{
		font-size: 1rem;
		color: $color3;

		&-info{
			float: right;
			font-size: 1.25rem;
			color: $color5;
		}
	}


	&-error{
		font-size: 0.875rem;
		margin: ($padding / 10) 0 0 0;
		padding: 0;
		color: $color9;
		text-align: left;
		line-height: 1.25rem;
		display: none;
	}


	&-hint{
		font-size: 0.875rem;
		margin: ($padding / 10) 0 0 0;
		padding: 0;
		color: $color6;
		text-align: left;
		line-height: 1.25rem;
	}

	//formularove pole
	&-input-wrapper{
		position: relative;
		&-prefix{
			.form-input{
				padding-left: $formItemSize;
			}
		}
	}

	&-select-rs-input{

		.Select-control{
			height: $formItemSize;
			border: 1px solid $color3;
			background-color: $color1;
			font-size: 1rem;
			border-radius: $borderRadiusMain;
		}
		.Select-menu-outer{
			border: 1px solid $color3;
			background-color: $color1;
			font-size: 1rem;
			border-radius: 0;
		}

		&.is-focused:not(.is-open),
		&.is-focused{
			.Select-control{
				border-color: $color10;
				outline-color: $color11;
				outline-offset: -2px;
				outline-style: auto;
				outline-width: 5px;
			}
		}
	}

	&-input{
		width: 100%;
		height: $formItemSize;
		border: 1px solid $color3;
		background-color: $color1;
		font-size: 1rem;
		border-radius: $borderRadiusMain;
		@include no-padding-margin();

		&:focus{
			border-color: $color10;
			outline-color: $color11;
			outline-offset: -2px;
			outline-style: auto;
			outline-width: 5px;
		}

		&:disabled{
			border-color: $color6;
			background-color: $color12;
			color: $color3;
			cursor: not-allowed;
		}
	}

	//ikone pred a za input
	&-input-icon{
		pointer-events: none;
		position: absolute;
		top: 0;
		left: 0;
		font-size: $formItemSize / 2;
		color: $color3;
		width: $formItemSize;
		height: $formItemSize;
		@include flex('row');
	}

	//formularove pole textarea
	&-textarea-wrapper{
	}
	&-textarea{

	}

	//wrapper pro tlacitka
	&-button-wrapper{
		height: $formItemSize;

	}

	//checkbox
	&-checkbox-wrapper{
		height: $formItemSize;

	}
	&-checkbox-label{
		min-height: $formItemSize;
		@include flex('row', 'flex-start');
		align-items: stretch;
		cursor: pointer;
		padding-left: 5px;
	}
	&-checkbox{
		@include flex('row', 'flex-start');
		@include no-padding-margin();
		flex: 0 0 auto;
		width: $formItemSize / 2;
	}
	&-checkbox-input{
		@include no-padding-margin();

	}
	&-checkbox-text{
		@include no-padding-margin();
		@include flex('row', 'flex-start');
		font-size: 1.125rem;
		flex: 1 0 auto;
	}

	//odkaz
	&-link-wrapper{
		height: $formItemSize;
		@include flex('row', 'flex-end');
	}
	&-link{
		font-size: 1.125rem;
		color: $color5;
		text-decoration: underline;
		cursor: pointer;

		&:hover{
			color: $color7;
		};

	}

}

.label-value-form{
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;

	.scrollable{
		overflow: auto;
		flex: 0 1 auto;
		padding-bottom: 15px;
		margin-right: 5px;
		padding-top: 5px;
	}

	.row{
		display: flex;
		align-items: center;
		min-height: 34px;

		&.buttons-container{
			justify-content: center;
			border-top: solid 2px $color3;
			padding-top: 10px;
			padding-bottom: 10px;
			//margin-top: 15px;
			flex: 0 0 auto;

			button,.button-wrapper{
				@include margin-between-items(6px);
			}
		}

		.label{
			margin-bottom: 5px;
			flex: 0 0 100px;
			text-align: right;
			margin-right: 20px;
			//min-height: 34px;
		}

		.value{
			margin-bottom: 5px;
			flex: 1 1 auto;
			//min-height: 34px;
			padding-right: 5px;
		}
	}

	.delimiter{
		height: 2px;
		background-color: $color18;
		margin-bottom: 5px;
		margin-left: 5px;
		margin-right: 5px;
		margin-top: 15px;
	}
}

// Defaultni nastaveni velikosti ikony checkboxu
.lib-form-component.checkbox-component{
	.form-component-content {
		i {
			font-size: 14px;
		}
	}
}

.lib-form-component.selectbox-component .form-component-content .options {
	border-bottom-left-radius: $panelBorderRadius;
	border-bottom-right-radius: $panelBorderRadius;
	box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
}

