@mixin backgroundGradient($start, $end) {
	background: linear-gradient(to bottom, $start 0%, $end 100%);
}

@mixin backgroundGradientToRight($start, $end) {
	background: linear-gradient(to right, $start 0%, $end 100%);
}

@mixin breakpoint-between($min, $max) {
	@media only screen and (min-width: $min) and (max-width: ($max - 1)) {
		@content;
	}
}

@mixin breakpoint-down($max) {
	@media only screen and (max-width: ($max - 1)) {
		@content;
	}
}

@mixin breakpoint-up($min) {
	@media only screen and (min-width: $min) {
		@content;
	}
}

@mixin breakpoint-height-down($max) {
	@media only screen and (max-height: ($max - 1)) {
		@content;
	}
}

@mixin breakpoint-height-up($min) {
	@media only screen and (min-height: $min) {
		@content;
	}
}

@mixin flex($direction: 'row', $justifyContent: 'center', $alignItems: 'center'){
	display: flex;
	flex-direction: unquote($direction);
	justify-content: unquote($justifyContent);
	align-items: unquote($alignItems);
}

@mixin absolute($top, $right, $bottom, $left, $position: 'absolute'){
	position: unquote($position);
	top: unquote($top);
	right: unquote($right);
	bottom: unquote($bottom);
	left: unquote($left);
}

@mixin no-padding-margin(){
	margin: 0;
	padding: 0;
}

@mixin margin-between-items($margin){
	margin: 0 $margin;

	&:first-child{
		margin-left: 0;
	}

	&:last-child{
		margin-right: 0;
	}
}

@mixin white-box(){
	background-color: #fff;
	box-shadow: 0 2px 5px rgba(0,0,0,.3);
	border-radius: 3px;
}
