@import './variables.scss';
@import './mixins.scss';

.form-page-container {
    @include flex('column', 'flex-start');
	padding-top: 40px;
	flex: 1 0 auto;
}

.dialog-container {
    @include flex('column', 'center');
	padding-top: 40px;
    flex: 1 0 auto;

    .dialog {
		background-color: rgba(254, 254, 254, .75);
		padding: $padding;
		box-shadow: $panelBoxShadow;
		position: relative;
        border-radius: $panelBorderRadius;
		width: 440px;
        margin: 5px;

        @media only screen and (max-height: (520px - 1)), (max-width: ($breakpointSm - 1)) {
			padding: ($padding / 2);
			width: 300px;
		}

        & > .title {
			text-align: center;
			font-size: 30px;
			margin-bottom: 30px;
        }

        & > .status {
            @include flex('row', 'center', 'center');
            font-size: 14px;

            & > .icon {
                font-size: 18px;
                color: $color7;

                &.bad {
                    color: $color9;
                }
            }

            & > .message {
                padding-left: 15px;
            }
        }

        & > .back-link-row {
            @include flex('row', 'flex-end');
            padding-top: 30px;

            & > .link {
                border: none;
                cursor: pointer;
                text-decoration: underline;
                color: $color5;
                padding: 0;
                margin: 0;
                background-color: transparent;
                font-size: 12px;
            }
        }
    }
}

